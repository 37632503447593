<template>
  <div>
    <b-row>
      <b-col>
        <h2>Your Learning</h2>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <div v-for="card in cards" :key="card.title" class="col-md-3">
        <div class="card mb-2 sm">
          <img :src="card.thumbnail" class="card-img-top" alt="Card Image" style="max-width: 100%; max-height: 250px;">
          <div class="card-body">
            <h5 class="card-title m-0">{{ card.title }}</h5>
            <small class="card-text">{{ card.description }}</small>
            <b-button class="mt-1" variant="primary" block :to="{ name: 'course-detail', params: { slug: card.slug } }">Detail</b-button>
          </div>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      cards: [
        {
          title: "Course 1",
          slug: "course-1",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel fringilla mauris, eu interdum ligula. Fusce feugiat massa nec justo pulvinar, non tincidunt nisi viverra. In hac habitasse platea dictumst",
          price_regular: 500000,
          price_actual: 250000,
          application_name: "solidworks",
          thumbnail: "https://mechtechidn.com/wp-content/uploads/2021/09/Screenshot-2021-09-24-at-13.03.48-150x150.png"
        },
      ]
    };
  },
};
</script>
